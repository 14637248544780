.product__banner {
  @media screen and (max-width: 400px) {
    background-size: 390px 310px;
  }
}
.cart-text-panel .cms_item.htmlPanel {
  max-width: 100%;
}
.removeBtn button,
.nextBtn {
  color: #454345;
  background: transparent;
  display: inline-block;
  border: 1px solid #454345;
  padding: 0.4rem 0.75rem;
  border-radius: 0.25rem;
  &:focus,
  &:hover,
  &:active {
    background: #454345;
    border: 1px solid #454345;
    color: #fff;
    text-decoration: none;
    // box-shadow: 0 0 0 0.2rem rgba(69, 67, 69, 0.5);
  }
}
.add_to_cart {
  color: #fff;
  background: #454345;
  display: inline-block;
  border: 1px solid #454345;
  padding: 0.1rem 0.75rem;
  border-radius: 0.25rem;
  height: $input-height;
  &:focus,
  &:hover {
    background: #000;
    border: 1px solid #000;
    color: #fff;
    text-decoration: none;
  }
}
.enquiry_btn {
  color: #fff;
  background: #454345;
  display: inline-block;
  border: 1px solid #454345;
  padding: 0.1rem 0.75rem;
  border-radius: 0.25rem;
  height: $input-height;
  line-height: 2;
  width: 50%;
  &:focus,
  &:hover {
    background: #000;
    border: 1px solid #000;
    color: #fff;
    text-decoration: none;
  }
}
p.shipping_restrict {
  margin-bottom: 2rem;
}
