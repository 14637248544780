.product_cont {
  position: relative;
}
.modal-bg {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  top: 0;
}
.popup-container {
  background: white;
  width: 60%;
}
.productInfo__container {
  padding: 2rem 4rem 0;
}
.productInfo__head {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-bottom: 2rem;
}
.productInfo__head img {
  width: 35%;
}
.productInfo__contents.row {
  margin: 0;
}
.productInfo__title {
  margin-bottom: 0.8rem;
  font-size: 1.7rem;
}
.productInfo__shortDes {
  margin-bottom: 0.5rem;
  font-size: 0.9rem;
}
.productInfo__desc {
  font-size: 0.9rem;
}
.productInfo__img img {
  width: 80%;
}
