.product-slider .container {
  @media screen and (min-width: 1200px) {
    max-width: 1000px;
  }
  @media screen and (min-width: 1600px) {
    max-width: 1400px;
  }
  @media screen and (min-width: 2000px) {
    max-width: 1600px;
  }
}

.product__container:hover .product__image__overlay {
  opacity: 1;
  transform: translateY(0);
  transition: 0.3s ease all;
  @media screen and (max-width: 576px) {
    opacity: 0;
  }
}
.productListContainer {
  position: relative;
  overflow: hidden;
  box-shadow: none;
  padding: 2.5rem 0 0.5rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (max-width: 576px) {
    justify-content: space-between;
    padding: 2rem 0;
  }
}
.product__tile__bg {
  transform: scale(1);
  transition: $transition-fast;
}
.productListContainer:hover .product__tile__bg {
  transform: scale(1.05);
  transition: $transition-fast;
}
.product__image__overlay {
  height: 100%;
  width: 100%;
  position: absolute;
  background: #2f2e2e;
  top: 0;
  opacity: 0;
  transform: translateY(100%);
  transition: 0.3s ease all;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1.5rem;
  font-size: 1rem;
  color: #fff;
  @media screen and (max-width: 576px) {
    display: none;
  }
}
.btn_container {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.btn_container .addCart_btn {
  color: black;
  background: #fff;
  border: 1px solid #000;
  border-radius: 4px;
  padding: 0.8rem 0.6rem;
  font-size: 0.6rem;
  font-weight: 400;
  &:focus,
  &:hover {
    background: $primary-color;
    color: #fff;
    text-decoration: none;
    border: 1px solid $primary-color;
  }
}
.product__image__overlay .btn_container .button.learnMore_button {
  background: #fff;
  padding: 0.8rem 0.6rem;
  font-size: 0.7rem;
  border: none;
  font-weight: 600;
  &:focus,
  &:hover {
    background: $primary-color;
    color: #fff;
    text-decoration: none;
    border: 1px solid $primary-color;
  }
}
.product__image__container {
  width: 100%;
  @media screen and (max-width: 576px) {
    width: 70%;
  }
}
.product-slider .container .productListName {
  padding: 2rem 20px 0.8rem 20px;
  @media screen and (max-width: 576px) {
    font-size: 1rem;
  }
}
.product_cont .productListName {
  padding: 1rem 1rem 0.8rem 1rem;
  margin-bottom: 0;
}
.productListPrice {
  font-size: 0.8rem;
  text-align: center;
  font-weight: 500;
  padding: 0 0.5rem 0.8rem 0.5rem;
  color: #454345;
}
.productListButton {
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (min-width: 576px) {
    display: none;
  }
}
.productListButton a.button {
  color: #454345;
  background: transparent;
  border: 1px solid #000;
  border-radius: 4px;
  padding: 0.6rem 1rem;
  font-weight: 400;
  font-size: 0.8rem;
}
.product_cont {
  @media screen and (min-width: 1200px) {
    max-width: 1000px !important;
  }
  @media screen and (min-width: 1600px) {
    max-width: 1400px !important;
  }
  @media screen and (min-width: 2000px) {
    max-width: 1600px !important;
  }
}
.product__tile__item {
  // margin-top: 1rem !important;
  // margin-bottom: 1rem !important;
  // @media screen and (max-width: 576px) {
  //   margin: 0 !important;
  // }
  margin: 0 !important;
}
.product_cont .product__tile__item {
  @media screen and (max-width: 576px) {
    max-width: 100%;
    flex: 0 0 100%;
  }
}
