/*
  <div class="tiles">
    <div class="tile">
      <div class="tile__content>
        <div class="tile__bg></div>
        <div class="tile__details></div>
      </div>
    </div>
  </div>
*/

@mixin tile--titleDesc(
  $breakpoints: (
    xs: 1,
    sm: 2,
    md: 2,
    lg: 3,
  ),
  $margin: 4rem,
  $padding: 1.4rem,
  $ratio: 70%
) {
  margin-left: 0;
  margin-right: 0;
  display: flex;
  justify-content: flex-start; //center;
  flex-wrap: wrap;

  &.center {
    justify-content: center;
  }

  .tile {
    padding: $padding/2;
    // height: 100%;
    height: inherit;
    @each $bp, $number in $breakpoints {
      @include media-breakpoint-up(#{$bp}) {
        flex: 0 0 100% / $number;
        max-width: 100% / $number;
      }
    }
  }

  .tile__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    box-shadow: 2px 2px 10px #999;
  }

  .tile__bg {
    @include bg-img();
    @include img-ratio($ratio);
    transition: all 0.2s;
  }
}
