.pageTiles {
  @include tile--titleDesc();
  margin: 2rem 0;
  @media screen and (max-width: 576px) {
    margin: 2rem 0;
  }
  &.useSlider {
    display: block;
    .tile {
      max-width: 100%;
    }
  }
}

.pageTile__content {
  &:hover,
  &:focus {
    text-decoration: none;
    // .pageTile_details {
    //   transition: $transition-base;
    //   background-image: url("/assets/wooden-background-texture-3.png");
    //   background-repeat: no-repeat;
    //   background-size: 140%;
    //   background-position-y: center;
    // }
    .small_tile {
      background-size: 170%;
    }
    .pageTile__title,
    .pageTile_desc {
      color: #fff;
      transition: $transition-base;
    }
    .tile__bg {
      transform: scale(1.02);
      transition: $transition-base;
    }
    .pageTile_btn {
      background: #fff;
      border: 1px solid #fff;
      color: #454345;
      text-decoration: none;
    }
  }
}

.pageTile__title {
  font-family: $family-header;
  font-size: 1.5rem;
  // text-transform: uppercase;
  font-weight: bold;
  line-height: 1;
  margin: 0 0 0.8rem 0;
  color: #fff;
}

.pageTile_desc {
  line-height: 1.3;
  font-size: 1rem;
  color: #fff;
  margin: 0 0 1rem 0;
}

.pageTile_details {
  position: relative;
  overflow: hidden;
  flex-grow: 1;
  text-align: left;
  // background-color: white;
  // border-bottom: 5px solid #ffc42f;
  padding: 1.8rem 1.5rem;
  background-size: 140%;
  background-image: url("/assets/wooden-background-texture-3.png");
  transition: $transition-base;
}

.contentPanel--dark {
  .pageTile_details:before {
    background-image: linear-gradient(
      to bottom left,
      rgba(#fff, 0.3),
      rgba(#fff, 0)
    );
  }

  .pageTile__title,
  .pageTile_desc {
    color: white;
  }
}

.pageTile_btn {
  font-size: 0.8rem;
  font-weight: $weight-bold;
  color: #fff;
  background: transparent;
  display: inline-block;
  border: 1px solid #fff;
  padding: 0.4rem 0.75rem;
  border-radius: 0.25rem;
}

.small_tile {
  background-size: 170%;
}
