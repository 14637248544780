.main-content {
  // padding-top: map-get($top-bar-height, mobile);

  // @include media-breakpoint-up(lg) {
  //   padding-top: map-get($top-bar-height, desktop);
  // }
  overflow: hidden;
}

.actions {
  margin: 1rem 0;
  text-align: center;
  border-radius: 0;
  button {
    color: #fff;
    background: transparent;
    display: inline-block;
    border: 1px solid #fff;
    padding: 0.4rem 0.75rem;
    border-radius: 0.25rem;
    &:focus,
    &:hover,
    &:active {
      background: #454345;
      border: 1px solid #454345;
      color: #fff;
      text-decoration: none;
    }
  }
}
